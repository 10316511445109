<template>
  <div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="500px"
      max-height="800px"
      persistent
    >
      <!-- Loading Spinner -->
      <div class="text-center" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          color="#7253CF"
          indeterminate
          style="
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
        ></v-progress-circular>
      </div>

      <!-- Video Library and Playlist Sections -->
      <v-card background-color="#f2f2f2" v-else>
        <template v-if="!playlistCreated">
          <!-- Dialog Title -->
          <v-card-title class="py-0 pt-7 dialog-header">
            <div class="dialogTitle-text" style="margin-left: 35%">
              Video Library
            </div>
            <v-spacer></v-spacer>
            <v-icon
              text
              small
              color="black"
              class="close-icon"
              @click="toggleBadgesModal({ show: false })"
            >
              mdi-close
            </v-icon>
          </v-card-title>

          <v-card-actions class="d-flex justify-center flex-column">
            <span class="explanatory-text">
              Choose up to 6 videos you'd like to watch now to create your
              playlist.
            </span>
            <v-btn
              color="#38227a"
              class="create-playlist-btn"
              @click="createPlaylist"
            >
              Create Workout
            </v-btn>
          </v-card-actions>

          <v-divider></v-divider>

          <!-- Video Library Content -->
          <v-card-text class="pt-5">
            <div
              v-for="category in categories"
              :key="category"
              class="video-section"
            >
              <h2 class="category-title">
                {{ category }}
                <v-icon
                  small
                  @click="toggleCategory(category)"
                  class="ml-2"
                  style="font-size: 25px"
                >
                  {{
                    expandedCategory === category
                      ? "mdi-chevron-up"
                      : "mdi-chevron-down"
                  }}
                </v-icon>
              </h2>

              <div v-if="expandedCategory === category">
                <div
                  v-for="(videos, subCategory) in wizfitVideoList[category]"
                  :key="subCategory"
                >
                  <h3
                    v-if="subCategory !== 'no_subcategory'"
                    class="subcategory-title"
                  >
                    {{ subCategory }}
                  </h3>

                  <v-row>
                    <v-col
                      v-for="video in showAll[category]
                        ? videos
                        : videos.slice(0, 3)"
                      :key="video.id"
                      cols="12"
                    >
                      <div class="video-item d-flex align-center">
                        <v-checkbox
                          v-model="video.selected"
                          class="mr-3"
                          @change="toggleVideoSelection(video)"
                        />
                        <img
                          :src="video.video_thumbnail"
                          alt="Video thumbnail"
                          class="video-thumbnail"
                        />
                        <div class="video-details ml-3">
                          <h4>{{ video.title }}</h4>
                          <span v-if="video.is_watched" style="color: green"
                            >Watched</span
                          >
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </div>

                <div class="text-right mt-3">
                  <a
                    href="#"
                    @click.prevent="toggleShowAll(category)"
                    class="see-all-videos"
                  >
                    {{ showAll[category] ? "Show less" : "See all videos >" }}
                  </a>
                </div>
              </div>
            </div>
          </v-card-text>
        </template>

        <!-- Playlist Section -->
        <template v-else -if="selectvideoFlag">
          <v-card-title class="py-0 pt-7 dialog-header">
            <div class="dialogTitle-text" style="margin-left: 25%">
              Selected Videos Playlist
            </div>
            <v-spacer></v-spacer>
            <v-icon
              text
              small
              color="black"
              class="close-icon"
              @click="toggleBadgesModal({ show: false })"
            >
              mdi-close
            </v-icon>
          </v-card-title>

          <v-card-actions class="d-flex justify-center flex-column">
            <v-btn
              color="#38227a"
              class="play-all-btn"
              @click="playVideo(index)"
            >
              Play All Videos to Start Workout
            </v-btn>
            <span class="rearrange-instruction">
              To rearrange your workout videos, simply click <br />and hold on a
              video to drag it into your <br />preferred position.
            </span>
          </v-card-actions>

          <v-divider></v-divider>

          <v-card-text class="pt-5">
            <draggable
              v-model="selectedVideos"
              handle=".handle"
              animation="200"
            >
              <v-row
                v-for="(video, index) in selectedVideos"
                :key="video.id || index"
              >
                <v-col cols="12">
                  <div class="video-item d-flex align-center">
                    <v-icon class="handle mr-3" medium>mdi-drag</v-icon>
                    <v-checkbox
                      v-model="video.selected"
                      class="mr-3"
                      style="display: none"
                    />
                    <div class="thumbnail-wrapper" @click="playVideo(index)">
                      <img
                        :src="video.video_thumbnail"
                        alt="Video thumbnail"
                        class="video-thumbnail"
                      />
                      <v-icon class="play-icon" large color="white"
                        >mdi-play-circle</v-icon
                      >
                    </div>
                    <div class="video-details ml-3">
                      <h4>{{ video.title }}</h4>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </draggable>
            <v-btn
              color="#38227a"
              class="back-btn"
              @click="playlistCreated = false"
            >
              Back to Create Workout
            </v-btn>
          </v-card-text>
        </template>
      </v-card>
    </v-dialog>
    <div ref="modal" class="modal">
      <video-player ref="videoPlayer"></video-player>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { API_GET_STUDENT_VIDEO_PROGRESS } from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
import draggable from "vuedraggable";
export default {
  name: "VideoBadgesModal",
  components: {
    VideoPlayer: () => import("@/components/VideoPlayer/VideoPlayerBadges"),
    draggable,
  },
  data() {
    return {
      loading: false,
      wizfitVideoList: {},
      showAll: {}, // State to track whether to show all videos for each category
      selectedVideos: [],
      filteredVideos: {}, // Store the filtered videos
      playlistCreated: false,
      videoList: {},
      currentPlayingIndex: null, // Add this line
      isPlaying: false, // Flag to track if a video is currently playing
      videoModalFlag: false,
      index: 0, // or some default value
      isModalVisible: true,
      expandedCategory: null,
      selectvideoFlag:false,
    };
  },
  computed: {
    ...mapGetters({
      getShow: "campaign/getSelectedCampaignBadgesshow",
    }),
    show: {
      get() {
        return this.getShow;
      },
      set(value) {
        return this.toggleBadgesModal({ show: value });
      },
    },
    categories() {
      return Object.keys(this.wizfitVideoList);
    },
    totalSelected() {
      return this.selectedVideos.length;
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else {
        this.closeModal();
      }
    },
  },
  methods: {
    ...mapActions({ showToast: "snackBar/showToast" }),
    openModal() {
      this.getDetails();
    },
    closeModal() {},
    ...mapActions({
      toggleBadgesModal: "campaign/toggleBadgesModal",
      toggleVideoModal: "videoSection/toggleModal",
      showToast: "snackBar/showToast",
    }),
    hideModal() {
      // Show or hide the modal based on the flag
      if (this.videoModalFlag) {
        this.$refs.modal.style.display = "block"; // Show the modal
      } else {
        this.$refs.modal.style.display = "none"; // Hide the modal
      }
    },
    showNotification(message) {
      this.notificationMessage = message;
      setTimeout(() => {
        this.notificationMessage = ""; // Clear message after 3 seconds
      }, 3000);
    },
    toggleCategory(category) {
      // Toggle the selected category, collapse others
      this.expandedCategory =
        this.expandedCategory === category ? null : category;
    },
    getDetails() {
      const successHandler = (res) => {
        this.wizfitVideoList = res.data.wizfit_video_list;
        this.initializeShowAllState();
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      let formData = {};
      formData["student_id"] = this.student;
      Axios.request_GET(
        API_GET_STUDENT_VIDEO_PROGRESS,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    initializeShowAllState() {
      // Initialize showAll state for each category as false (show only 3 videos initially)
      this.categories.forEach((category) => {
        this.$set(this.showAll, category, false);
      });
    },
    toggleShowAll(category) {
      // Toggle the showAll state for the clicked category
      this.$set(this.showAll, category, !this.showAll[category]);
    },
    toggleVideoSelection(video) {
      // Check the current count of selected videos
      const selectedCount = this.selectedVideos.length;

      if (video.selected) {
        // If the video is being selected
        if (selectedCount < 6) {
          this.selectedVideos.push(video); // Add the video to the selected list
        } else {
          // If trying to select more than 6 videos, show an alert and prevent selection
          this.showToast({
            message: "You are allowed to select only up to 6 videos.",
            color: "red",
          });

          // Force the checkbox to uncheck by immediately setting `video.selected` to `false`
          setTimeout(() => {
            video.selected = false; // Deselect the video
          }, 0);
        }
      } else {
        // If the video is being deselected, remove it from the selectedVideos array
        this.selectedVideos = this.selectedVideos.filter(
          (v) => v.id !== video.id
        );
        video.selected = false; // Ensure that it is deselected
      }
    },

    playVideo(index) {
      this.videoModalFlag = true;
      this.hideModal();
      // Set the current index
      this.currentVideoIndex = index;

      // Ensure currentVideoIndex is set correctly
      const videoToPlay = this.selectedVideos[this.currentVideoIndex];

      // Make sure videoToPlay is defined
      if (!videoToPlay) {
        console.error("Video not found at index:", this.currentVideoIndex);
        return; // Exit if there's no video to play
      }

      const videoUrl = videoToPlay.video_url; // Get video URL
      const videoPlayer = this.$refs.videoPlayer.player;
      videoPlayer.src({}); // Clear previous sources
      videoPlayer.off("ended", this.onVideoEnded); // Remove existing event listeners

      // Set the source and play the video
      videoPlayer.src({ type: "video/mp4", src: videoUrl });

      // Add the new event listener for when the video ends
      videoPlayer.on("ended", this.onVideoEnded);

      // Start playing the video
      videoPlayer
        .play()
        .then(() => {
          console.log("Video started playing.");
        })
        .catch((error) => {
          console.error("Error playing video:", error);
        });
    },

    onVideoEnded() {
      console.log("Video ended event triggered."); // Debugging log
      this.currentVideoIndex++; // Move to the next index

      // Ensure there are more videos to play
      if (this.currentVideoIndex < this.selectedVideos.length) {
        this.playVideo(this.currentVideoIndex); // Play the next video
      } else {
        this.toggleVideoModal({ show: false }); // Close modal when done
        console.log("All videos have finished playing.");
        this.currentVideoIndex = -1; // Reset index
        location.reload();
      }
    },

    createPlaylist1() {
      // Filter the wizfitVideoList to only include selected videos
      this.filteredVideos = {};
      Object.keys(this.wizfitVideoList).forEach((category) => {
        this.filteredVideos[category] = Object.entries(
          this.wizfitVideoList[category]
        )
          .map(([subCategory, videos]) => {
            // Filter videos based on selection
            const selectedVideosInSubCategory = videos.filter(
              (video) => video.selected
            );
            if (selectedVideosInSubCategory.length > 0) {
              return [subCategory, selectedVideosInSubCategory];
            }
            return null;
          })
          .filter(Boolean) // Remove empty entries
          .reduce((acc, [subCategory, videos]) => {
            acc[subCategory] = videos;
            return acc;
          }, {});
      });

      // Update the displayed video list to show only selected videos
      this.wizfitVideoList = this.filteredVideos;
    },
    createPlaylist() {
    if(this.selectedVideos.length<=0)
    {
       this.showToast({
            message: "Without select video you cannot create workout",
            color: "red",
          });
          this.selectvideoFlag=false;
          
    }
    else{
     this.selectvideoFlag=true;
      // Mark playlist as created
      this.playlistCreated = true;
      }
    },
    moveUp(index) {
      if (index > 0) {
        const videoToMove = this.selectedVideos[index];
        this.selectedVideos.splice(index, 1);
        this.selectedVideos.splice(index - 1, 0, videoToMove);
      }
    },
    moveDown(index) {
      if (index < this.selectedVideos.length - 1) {
        const videoToMove = this.selectedVideos[index];
        this.selectedVideos.splice(index, 1);
        this.selectedVideos.splice(index + 1, 0, videoToMove);
      }
    },
    // Play all videos in sequence

    // Method to play a single video
  },
  mounted() {
    // this.hideModal();
    if (this.$route.query.student) {
      this.student = this.$route.query.student;
    }
  },
};
</script>

<style scoped>
.dialog-header {
  background-color: #ffffff;
  color: black;
}
.close-icon {
  cursor: pointer;
  position: relative;
  bottom: 22px;
}
.dialogTitle-text {
  text-align: center;
  color: #38227a;
}
.explanatory-text {
  font-size: 20px;
  text-align: center;
  margin-bottom: 15px;
}
.create-playlist-btn,
.play-all-btn,
.back-btn {
  background-color: #38227a;
  color: white;
  margin-top: 20px;
}
.create-playlist-btn {
  font-size: 16px;
  background-color: #38227a;
}
.video-section {
  margin-bottom: 20px;
}
.category-title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}
.subcategory-title {
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 10px;
}
.video-thumbnail {
  width: 150px;
  height: auto;
  float: left;
  margin-right: 10px;
}
.video-details h4 {
  font-size: 14px;
  margin: 0;
}
.rearrange-instruction {
  font-size: 16px;
  text-align: center;
}
@media only screen and (max-width: 600px) {
  .dialogTitle-text,
  .category-title,
  .subcategory-title,
  .explanatory-text {
    font-size: 16px;
  }
  .video-thumbnail {
    width: 100px;
  }
  .play-all-btn,
  .create-playlist-btn,
  .back-btn {
    font-size: 14px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
